import { CalculatedResultItemProps, CartProductItemProps } from "@/components/Cart/constants";
import { MinProductItemProps } from "@/components/Product/constants";
import { AttributeItem, CategoryItem } from "@/lib/constants";
import { v4 as uuidv4 } from 'uuid';

// user
export interface UserState {
  userInfo: string;
  updateUser: (user: User) => void;
  getUser: () => User;
}

export interface User {
  user_id: number | undefined;
  level: string;
  token: string;
  refresh_token: string;
  barcode: string;
  email: string;
  mooimom_point: number;
  name: string;
  country_code: string;
  phone: string;
  referral_code: string;
  uuid: string;
  birthday: string | null;
  gender: string;
}

export const initUser: User = {
  user_id: undefined,
  level: '',
  token: '',
  refresh_token: '',
  barcode: '',
  email: '',
  mooimom_point: 0,
  name: '',
  country_code: '',
  phone: '',
  referral_code: '',
  uuid: uuidv4(),
  birthday: null,
  gender: '',
}

// wishlist
export interface WishlistState {
  wishlist: Wishlist;
  resetWishlist: (wishlist: Wishlist) => void;
  updateWishlist: (wishlist: Wishlist) => void;
}

export interface Wishlist {
  products: Array<MinProductItemProps>
}

export const initWishlist: Wishlist = {
  products: []
}

// cart
export interface CartsState {
  carts: string;
  updateCarts: (carts: Carts) => void;
  getCarts: () => Carts;
}

export interface Carts {
  update_date: number | null;
  data: Array<CartProductItemProps>;
}

export const initCarts: Carts = {
  update_date: null,
  data: []
}

// address
export interface AddressState {
  addressInfo: string;
  updateAddress: (address: Address) => void;
  getAddress: () => Address;
}

export interface Address {
  id: number | undefined;
  first_name: string;
  last_name: string;
  country_code: string;
  mobile: string;
  country: string;
  country_value: string;
  state: string;
  city: string;
  street: string;
  zip_code: string;
  email: string;
}

export const initAddress: Address = {
  id: undefined,
  first_name: '',
  last_name: '',
  country_code: '',
  mobile: '',
  country: '',
  country_value: '',
  state: '',
  city: '',
  street: '',
  zip_code: '',
  email: ''
}

// calculate result
export interface ResultState {
  resultData: string;
  updateResult: (result: CalculatedResultItemProps) => void;
  getResult: () => CalculatedResultItemProps;
}

export const initResult: CalculatedResultItemProps = {
  voucher_list: [],
  use_voucher: true,
  voucher_discount: 0,
  voucher_gift: [],
  promo_list: [],
  gift_list: [],
  total_amount: 0,
  normal_amount: 0,
  gift_promo_list: [],
  discount_shipping: {
    name: "",
    free_shipping: false,
    min_amount: 0,
    shipping_charge: 0,
    related_products: [],
  },
  flash_sales: [],
  discount: 0,
  discount_name: "",
  status: "",
  subtotal: 0,
  voucher_type: "",
}

// Top Banner
export interface TopBannerState {
  topBanner: string;
  updateTopBanner: (topBanner: TopBanner) => void;
  getTopBanner: () => TopBanner;
}

export interface TopBannerItem {
  name: string | null;
  link: string | null;
  desktop_image: string | null;
  mobile_image: string | null;
  image_alt: string | null;
}

export interface TopBanner {
  update_date: number | null;
  data: Array<TopBannerItem>;
}

export const initTopBanner: TopBanner = {
  update_date: null,
  data: []
}

// categories
export interface CategoryState {
  categories: string;
  updateCategories: (categories: Categories) => void;
  getCategories: () => Categories;
}

export interface Categories {
  update_date: number | null;
  data: Array<CategoryItem>;
}

export const initCategories: Categories = {
  update_date: null,
  data: []
}

// attributes
export interface AttributeState {
  attributes: string;
  updateAttributes: (attributes: Attributes) => void;
  getAttributes: () => Attributes;
}

export interface Attributes {
  update_date: number | null;
  data: Array<AttributeItem>;
}

export const initAttributes: Attributes = {
  update_date: null,
  data: []
}
