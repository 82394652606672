import axios from "axios";
import { AttributeItem, CategoryItem, VoucherItem, VoucherListResponse } from "./constants";
import { CalculateResultParamItemProps, CalculatedResultItemProps, CartProductItemProps } from "@/components/Cart/constants";
import { initResult } from "@/stores/initialState";
import { AddToCartProductItemProps, BundleMinProductItemProps, MinProductItemProps, ProductDetailItemProps, ProductVariantItemProps } from "@/components/Product/constants";


export const getCategories = async (): Promise<Array<CategoryItem>> => {
  try {  
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_CATEGORY!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getAttributes = async (): Promise<Array<AttributeItem>> => {
  try {
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ATTRIBUTE!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getCalculatedResult = async (
  data: CalculateResultParamItemProps, token: string
): Promise<CalculatedResultItemProps> => {
  try {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ORDER! + "/calculate",
      data,
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return initResult;
  }
}

export const fetchVouchers = async (token: string, data: any): Promise<VoucherListResponse | Array<VoucherItem>> => {
  try {
    const response = await axios.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok" || response.status === 200) {
      return response.data;
    } else {
      try {
        const body = {data: {error: response.data, type: "fetchVouchers"}, timestamp: new Date().getTime()};
        await axios.post(
          "/api/report",
          {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
        )
      } catch (error) {
        console.log(error);
      }
      return {
        status: "error",
        voucher_list: [],
      };
    }
  } catch (error: any) {
    // if (error?.message !== "Duplicate request detected") {
    //   try {
    //     const body = {data: {error: error?.response?.data || error, type: "fetchVouchers"}, timestamp: new Date().getTime()};
    //     await axios.post(
    //       "/api/report",
    //       {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
    //     )
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    return {
      status: "error",
      voucher_list: [],
    };
  }
}

export const bindVoucher = async (token: string, data: any): Promise<VoucherItem | string> => {
  try {
    const response = await axios.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok") {
      return response.data;
    } else {
      let desc = "";

      switch (response.data.error) {
        case "Invalid credentials":
          desc = "An error occurred, please log in again and try again";
          break;

        case "this voucher can only be used for new customer":
        case "this coupon is only available to customers who have already purchased":
        case "already used this voucher":
        case "personal usage has reached the upper limit":
        case "the number of coupons used is full":
        case "this coupon is bound to someone who can use it":
          desc = response.data.error;
          break;

        case "you already have this voucher":
          desc = "You have entered this voucher already.";
          break;

        case "cannot find voucher":
          desc = "Voucher not found.";
          break;

        default:
          try {
            const body = {data: {...data, error: response.data.error, type: "bindVoucher"}, timestamp: new Date().getTime()};
            await axios.post(
              "/api/report",
              {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
            )
          } catch (error) {
            console.log(error);
          }
          desc = "Voucher does not exist";
      }

      return desc;
    }
  } catch (error: any) {
    // if (error?.message !== "Duplicate request detected") {
    //   try {
    //     const body = {data: {...data, error: error?.response?.data || error, type: "bindVoucher"}, timestamp: new Date().getTime()};
    //     await axios.post(
    //       "/api/report",
    //       {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
    //     )
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    return error.response.data.error;
  }
}

export const getNewToken = async (
  token: string, refresh_token: string, uid: string
): Promise<string> => {
  try {
    const response = await axios.post(
      '/api/refresh',
      { token: refresh_token, username: uid },
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return "";
  }
}

export const get_sales_price = (
  product: ProductDetailItemProps | MinProductItemProps | AddToCartProductItemProps | CartProductItemProps | BundleMinProductItemProps, variant: ProductVariantItemProps | null
) : {price: number | Array<number>, promotion: boolean} => {
  if (variant === null || variant === undefined) {
    if (product.special_price && product.special_price !== undefined) {
      if (Array.isArray(product.special_price)) {
        let differentPrice = false;

        for (const variant of product.product_variant_list) {
          if (variant.special_price && variant.special_price === product.special_price[1]) {
            differentPrice = true;
            break;
          }
        }

        if (!differentPrice) {
          return {price: product.special_price[0], promotion: false};
        } else {
          return {price: [product.special_price[0], product.special_price[1]], promotion: true};
        }
      } else {
        return {price: product.special_price, promotion: (product.special_price === product.sales_price && product.msrp === 0) || !((product.special_price !== product.sales_price) || (product.msrp !== 0 && product.special_price < product.msrp))};
      }
    } else {
      return {price: product.sales_price, promotion: product.sales_price === product.msrp || product.msrp === 0};
    }
  } else {
    if (variant.special_price !== undefined && variant.special_price !== null && variant.special_price !== 0) {
      return {price: variant.special_price, promotion: variant.special_price === variant.sales_price && product.msrp === 0 || !((variant.special_price !== variant.sales_price) || (product.msrp !== 0 && variant.special_price < product.msrp))};
    } else {
      return {price: variant.sales_price, promotion: false};
    }
  }
}

export const get_original_price = (
  product: ProductDetailItemProps | MinProductItemProps | AddToCartProductItemProps | CartProductItemProps | BundleMinProductItemProps, variant: ProductVariantItemProps | null
) : number | null => {
  if (variant === null || variant === undefined) {
    if (Array.isArray(product.special_price)) {
      let differentPrice = false;

      for (const variant of product.product_variant_list) {
        if (variant.special_price && variant.special_price === product.special_price[1]) {
          differentPrice = true;
          break;
        }
      }

      if (differentPrice) {
        return null;
      } else if (product.msrp !== 0 && product.msrp > product.special_price[1]) {
        return product.msrp;
      } else {
        return product.sales_price;
      }
    } else if ((product.special_price === undefined && (product.sales_price === product.msrp || product.msrp === 0)) || (product.special_price === product.sales_price && product.msrp === 0)) {
      return null;
    } else if (product.msrp !== 0 && product.msrp > product.special_price) {
      return product.msrp;
    } else {
      return product.sales_price;
    }
  } else {
    if (Array.isArray(variant.special_price) || (variant.special_price === variant.sales_price && product.msrp === 0)) {
      return null;
    } else if (product.msrp !== 0 && product.msrp > variant.special_price) {
      return product.msrp;
    } else if (variant.special_price !== undefined && variant.special_price !== null && variant.special_price !== 0) {
      return variant.sales_price;
    } else {
      return null;
    }
  }
}
